import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Chat, Compass, Envelope, Heart, Phone } from "react-bootstrap-icons";
import { BvB } from "../components/icons";
import { Layout } from "../components/Layout";

export default function () {
  return (
    <Layout
      title="Heidekojoten - BVB Fanclub Soltau '09"
      description="BVB Fanclub mit über 100 Mitgliedern im Heidekreis!"
    >
      {/* HERO */}
      <section
        id="home"
        className="py-12 md:pt-24 bg-gradient-to-tr from-gray-800 via-black to-gray-900 text-white"
      >
        <div className="container grid grid-cols-1 md:grid-cols-2 ">
          <div className="text-center space-y-12 my-12 lg:text-left lg:mx-12">
            <div className="flex items-center justify-center">
              <StaticImage
                src={"../images/heidekojoten-3d.png"}
                layout="constrained"
                alt="Heidekojoten Logo"
                className="object-contain"
              />
            </div>
            <h1 className="sr-only text-4xl xl:text-6xl font-extrabold uppercase tracking-tighter text-primary-500">
              Heidekojoten
            </h1>
            <div>
              <p className="text-2xl lg:text-3xl font-semibold">
                Der BVB-Fanclub für den Heidekreis.
              </p>
              <p className="text-primary-500 text-2xl lg:text-3xl font-semibold">
                Seit 2009.
              </p>
            </div>
            <div>
              <a
                href="/#mitgliedschaft"
                className="block md:inline-block bg-primary-500 text-lg uppercase font-semibold text-black hover:bg-primary-400 px-10 py-3 rounded-xl duration-300"
              >
                Werde Mitglied
              </a>
              <a
                href="https://forum.heidekojoten.de/"
                className="block md:inline-block mt-3 md:ml-3 md:mt-0 bg-white text-lg uppercase font-semibold text-black hover:bg-primary-200 px-10 py-3 rounded-xl duration-300"
              >
                Zum Forum
              </a>
            </div>
          </div>
          <div className="grid grid-cols-4 rounded-xl">
            <StaticImage
              className="row-span-2 w-full h-full rounded-10xl rounded-tr-none"
              aspectRatio={1 / 2}
              imgClassName="rounded-10xl rounded-tr-none"
              transformOptions={{ grayscale: true }}
              src="../images/drinking.jpg"
              alt=""
              objectFit="cover"
            />
            <StaticImage
              className="col-span-2 row-span-2 w-full h-full rounded-10xl rounded-bl-none"
              aspectRatio={1 / 1}
              imgClassName="rounded-10xl rounded-bl-none"
              transformOptions={{ grayscale: true }}
              src="../images/flagge.jpg"
              alt=""
              objectFit="contain"
            />
            <StaticImage
              className="w-full h-full rounded-10xl rounded-bl-none"
              aspectRatio={1 / 1}
              imgClassName="rounded-10xl rounded-bl-none"
              transformOptions={{ grayscale: true }}
              src="../images/stadion-fankurve.jpg"
              alt=""
              objectFit="cover"
            />
            <StaticImage
              className="w-full h-full rounded-10xl rounded-bl-none"
              aspectRatio={1 / 1}
              imgClassName="rounded-10xl rounded-bl-none"
              transformOptions={{ grayscale: true }}
              src="../images/bus_trip.jpg"
              alt=""
              objectFit="cover"
            />
            <div>
              <BvB />
            </div>
            <StaticImage
              className="col-span-2 w-full h-full rounded-10xl rounded-l-none"
              imgClassName="rounded-10xl rounded-l-none"
              aspectRatio={2 / 1}
              transformOptions={{ grayscale: true }}
              src="../images/gruendung.jpg"
              alt=""
              objectFit="cover"
            />
            <StaticImage
              className="row-span-2 w-full h-full rounded-10xl rounded-bl-none"
              aspectRatio={1 / 2}
              imgClassName="rounded-10xl rounded-bl-none"
              transformOptions={{ grayscale: true }}
              src="../images/football_hold.jpg"
              alt=""
              objectFit="cover"
            />
            <StaticImage
              className="col-span-3 row-span-2 w-full h-full rounded-10xl rounded-tr-none"
              aspectRatio={3 / 2}
              imgClassName="rounded-10xl rounded-tr-none"
              transformOptions={{ grayscale: true }}
              src="../images/stadion-vor-dem-spiel.jpg"
              alt=""
              objectFit="cover"
            />
            <StaticImage
              className="w-full h-full rounded-10xl rounded-br-none"
              aspectRatio={1 / 1}
              imgClassName="rounded-10xl rounded-br-none"
              transformOptions={{ grayscale: true }}
              src="../images/football_kick.jpg"
              alt=""
              objectFit="cover"
            />
          </div>
        </div>
      </section>
      {/* Eigenschaften */}
      <section id="1" className="container py-12 md:py-24">
        <h2 className="text-black dark:text-white mb-12 text-center text-3xl font-bold">
          Das sind Wir
        </h2>
        <div className="w-full h-1 bg-gradient-to-r from-white via-primary-500 to-white" />
        <div className="grid grid-cols-1 md:grid-cols-2">
          {(
            [
              {
                icon: <Heart className="w-full h-full" />,
                title: "We Walk Together",
                text: "Wir sind die Heidekojoten. Seit über 10 Jahren ein Rudel schwarz-gelber Herzen. Aber wir sind mehr als nur ein Fanclub. Sozial engagiert und über den Fußball hinaus miteinander verbunden sind wir eine große Familie.",
              },
              {
                icon: <BvB />,
                title: "We Stay Together",
                text: "Wir unterstützen unseren Verein. Im Stadion, oder als Gruppe in der Bar. Unsere Gemeinschaft ist unsere Stärke, auch in schweren Zeiten. Wir halten zusammen.",
              },
            ] as {
              icon: JSX.Element;
              title: string;
              text: string;
            }[]
          ).map((value) => (
            <div className="w-full my-6" key={value.title}>
              <div className="m-4 space-y-6 text-center">
                <div className="text-primary-500 h-20 w-20 mx-auto">
                  {value.icon}
                </div>
                <h3 className="text-primary-700 text-xl md:text-2xl font-semibold uppercase">
                  {value.title}
                </h3>
                <p className="text-gray-600 md:text-lg">{value.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Geschichte */}
      <div className="bg-gradient-to-bl from-gray-600 via-gray-700 to-gray-600">
        <section id="geschichte" className="container py-12 md:py-24">
          <h2 className="text-white mb-12 text-center text-3xl font-bold">
            Unsere Geschichte
          </h2>
          <div className="grid grid-cols-6">
            {(
              [
                {
                  title: "Gründung BVB",
                  text: "Im Jahre 1909 war die Geburtsstunde des Ballspiel-Verein Borussia aus Dortmund. Der Abend des 19. Dezember 1909 im Restaurant “Am Waldspitz” am Borsigplatz verlief etwas tumultartig, letztlich gab es 18 begeisterte Fußballer, die den Grundstein für einen großartigen, emotionalen und erfolgreichen Verein gelegt haben.",
                },
                {
                  title: "Die Heidekojoten werden Geboren",
                  image: (
                    <StaticImage
                      className="my-3"
                      src="../images/gruendung.jpg"
                      alt="Gründung der Heidekojoten 2009"
                    />
                  ),
                  text: "Springen wir in der Geschichte 100 Jahre nach vorn. Am Abend des 09. September 2009 trafen sich 11 begeisterte Fußballfans im Restaurant “Alexanders” in Soltau um einen BVB-Fanclub zu gründen. Es gab, anders als 100 Jahre zuvor, keine Tumulte oder Widerstände. So wurde 19:09 Uhr die Gründung des BVB-Fanclubs “Heidekojoten ’09 e.V. Soltau” beschlossen. Was an diesem Abend wohl niemand ahnte, wie viele BVB Fans im Heidekreis auf eine Fangemeinschaft gewartet haben.  Innerhalb von nur einem Jahr wuchs die Mitgliederzahl rasant. Heute sind es mehr als 100 Kojotinnen und Kojoten, die sich dem Heidekojoten-Rudel angeschlossen haben.",
                },
                {
                  title: "Neue Liebe",
                  image: (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                      <StaticImage
                        src="../images/flagge.jpg"
                        alt="Das Rudel beim DFB Pokalfinale"
                        objectFit="contain"
                      />
                      <StaticImage
                        src="../images/10jahre.jpg"
                        alt="Wir werden 10!"
                        objectFit="contain"
                      />
                    </div>
                  ),
                  text: "Der Geist der Begeisterung und Emotionalität für den Ballspiel-Verein Borussia aus Dortmund, der vor mehr als 100 Jahren entstanden war, ist wesentlicher Bestandteil der Heidekojoten geworden.  Wenn immer es möglich ist, setzt sich ein Reisebus, ein Zug, mehrere Autos oder ein Flugzeug mit Kojotinnen und Kojoten in Bewegung um die Spiele des BVB in Dortmund, Deutschland oder Europa zu sehen.",
                },
                {
                  title: "We Walk Together, We Stay Together",
                  text: "Im Jahr 2020 hat sich ein Leitspruch entwickelt: “we walk together, we stay together”. In dieser, in jedem Bereich, schwierigen Zeit hat sich gezeigt, wie wichtig Zusammenhalt und Gemeinschaft ist. Wenn wir nicht zusammen in die Stadien fahren können, dann müssen wir die Atmosphäre zu uns holen. Digitale Fanclubtreffen und Fußball schauen waren gewöhnungsbedürftig und hilfreich, werden aber nie eine echte Alternative werden. Es fehlen die Fahrten, die Gesänge, die Stadionwurst und das Gefühl der Gemeinschaft!  ",
                },
                {
                  title: "Gemeinsam haben wir noch mehr Spaß",
                  text: "Über die Jahre hat sich eine Freundschaft mit dem Werder Bremen Fanclub “Grün-Weiße-Heidegeister” und dem BVB Fanclub “Leine-Weser-Power” entwickelt. Mit den Jungs und Mädels von Leine-Weser-Power hatten wir erlebnisreiche Reisen bei Wind und Wetter in Deutschland und Europa, was uns eng zusammen geschweißt hat. Mit den Soltauer “Grün-Weiße-Heidegeister” haben wir nicht nur die fast schon traditionellen Fahrten mit Bus oder Fähre zu den Begegnungen zwischen Werder Bremen und BVB, sondern richteten gemeinsam Freundschaftsspiele aus, bei der die Heidekojoten meistens als Sieger vom Platz gingen. 😎 Der Wanderpokal ist immer noch in unserem Besitz",
                },
                {
                  title: "Sport für was Gutes",
                  text: "Die Kojotinnen und Kojoten wollten etwas für diejenigen tun, für die diese Zeit schwieriger ist, als für andere . So wurde die Aktion “Sport für was Gutes” ins Leben gerufen. Bei dieser Aktion hat sich das Kojotenrudel sportlich engagiert und so einen Betrag von 600€ erarbeitet, der an “Die Tafel” im Heidekreis, “Leuchte auf” BVB Stiftung sowie SportBund Heidekreis e.V. gespendet wurden.",
                },
              ] as { title?: string; text?: string; image?: JSX.Element }[]
            ).map((value, index, array) => (
              <React.Fragment key={index}>
                <div className="col-span-1 relative flex">
                  {index === 0 ? (
                    <div className="h-1/2 w-1 absolute left-1/2 top-1/2 transform -translate-x-1/2 bg-white" />
                  ) : index === array.length - 1 ? (
                    <div className="h-1/2 w-1 absolute left-1/2 top-0 transform -translate-x-1/2 bg-white" />
                  ) : (
                    <div className="h-full w-1 absolute left-1/2 transform -translate-x-1/2 bg-white" />
                  )}
                  <div className="h-6 w-6 rounded-full absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 bg-white" />
                </div>
                <div className="col-span-5 my-2">
                  <div className="bg-primary-500 rounded-xl p-3 md:p-6">
                    {value.title ? (
                      <h3 className="text-gray-700 text-2xl font-semibold">
                        {value.title}
                      </h3>
                    ) : null}
                    {value.image ? value.image : null}
                    {value.text ? (
                      <p className="text-black text-md md:text-lg my-2">
                        {value.text}
                      </p>
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </section>
      </div>
      {/* Mitgliedschaft */}
      <section id="mitgliedschaft" className="container py-12 md:py-24">
        <h2 className="text-black mb-8 text-center text-3xl font-bold">
          Deine Mitgliedschaft beginnt hier
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="">
            <StaticImage
              className="h-full rounded-xl"
              imgClassName="rounded-xl"
              src="../images/stadion-vor-dem-spiel.jpg"
              alt="BVB Stadion vor dem Spiel"
              layout="fullWidth"
            />
          </div>
          <div className=" flex flex-col justify-around">
            <div>
              <h3 className="text-gray-700 text-2xl font-bold mb-6">
                Werde Teil des Rudels
              </h3>
              <p className="text-lg text-gray-800">
                Um ein Kojote zu werden, musst du nur den Mitgliedsantrag
                ausfüllen und an uns schicken.
              </p>
            </div>
            <div>
              <h3 className="text-gray-700 text-2xl font-bold mb-6 mt-6">
                Feier' mit uns Fußball und den BVB
              </h3>
              <p className="text-lg text-gray-800">
                Die <span className="text-xl font-semibold">25€ jährlich</span>{" "}
                sind bei den Kojoten gut angelegt. Wir organisieren Fahrten zu
                den Spielen, Rudelgucken und andere Events.
              </p>
            </div>
            <div>
              <h3 className="text-gray-700 text-2xl font-bold mb-6 mt-6">
                Verbinde dich mit uns in unserem Forum
              </h3>
              <p className="text-lg text-gray-800">
                Tausche dich mit anderen Kojotinnen und Kojoten in unserem Forum
                aus. Hier findet alles organisatorische rund um die Heidekojoten
                statt.
              </p>
            </div>
            <div className="mt-8">
              <a
                href="/downloads/Mitgliedsantrag.pdf"
                className="block my-3 px-10 py-3 text-center text-white uppercase font-semibold bg-black hover:bg-gray-700 rounded-xl duration-300 2xl:text-lg"
              >
                Mitgliedsantrag
              </a>
              <a
                href="https://forum.heidekojoten.de/"
                className="block my-3 px-10 py-3 text-center text-black uppercase font-semibold bg-primary-500 hover:bg-primary-200 rounded-xl duration-300 2xl:text-lg"
              >
                Forum
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Kontakt */}
      <div className="bg-gradient-to-br from-black to-gray-700">
        <section id="kontakt" className="container py-12 md:py-24">
          <div className="text-3xl text-center font-bold mb-6 text-white">
            Kontakt
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="flex flex-col justify-around">
              {(
                [
                  {
                    href: "https://www.google.com/maps/place/T%C3%B6pingen+33,+29633+Munster",
                    icon: <Compass className="h-10 w-10" />,
                    text: (
                      <>
                        <span className="block">
                          Heidekojoten ´09 e.V. c/o Denis Krüger
                        </span>
                        <span className="block">
                          Töpingen 33, 29633 Munster
                        </span>
                      </>
                    ),
                  },
                  {
                    href: "mailto:vorstand@heidekojoten.de",
                    icon: <Envelope className="h-10 w-10" />,
                    text: "vorstand@heidekojoten.de",
                  },
                  {
                    href: "tel:+491745697977",
                    icon: <Phone className="h-10 w-10" />,
                    text: "+49 174 5697977",
                  },
                  {
                    href: "https://forum.heidekojoten.de/",
                    icon: <Chat className="h-10 w-10" />,
                    text: "https://forum.heidekojoten.de/",
                  },
                ] as {
                  href: string;
                  icon: JSX.Element;
                  text: string | JSX.Element;
                }[]
              ).map((value) => (
                <a
                  href={value.href}
                  key={value.href}
                  className="my-6 flex md:flex-row-reverse items-center justify-start text-primary-500 hover:text-primary-400 duration-300"
                >
                  <div className="mx-6">{value.icon}</div>
                  <p className="text-lg">{value.text}</p>
                </a>
              ))}
            </div>
            <div>
              <StaticImage
                className="rounded-xl"
                imgClassName="rounded-xl"
                src="../images/ball.jpg"
                alt="Ball auf dem Rasen"
                layout="fullWidth"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
